@import "../../_variables.scss";


.alert-message .MuiSnackbar-root {
    position: absolute;

    .alert-info-message {
        padding: 1rem;
        margin-top: 2.5rem;
        border-color: 0.0625rem solid $color-blue-gray;
        border-radius: 0.25rem;

        .info-icon {
            color: $color-info;
            margin-right: 0.375rem;
            float: left;
        }

        .alert-note {
            font-size: 1rem;
            font-weight: 500;
        }

        .close-icon {
            margin-right: 0.75rem;
        }
    }
}