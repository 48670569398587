.workup-created-message {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
//   align-items: flex-start;
  padding: 0px;
  gap: 10px;

//   position: absolute;
  width: 392px;
  height: 80px;
//   left: 524px;
//   top: 84px;

  background: #eefff1;
  /* Secondary/Ortho Olive */

  border: 1px solid #789d4a;
  box-shadow: 0px 8px 16px rgba(39, 40, 51, 0.2);
  border-radius: 4px;
}
