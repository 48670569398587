$color-primary: #CF2F44;
$color-primary-light: #FDEBF0;
$color-primary-dark: #8F191C;
$color-secondary: #34383C;
$color-secondary-dark: #182027;
$color-black-light: #474D54;
$color-black-medium: #272833;
$color-black: #000000;
$color-gray: #E3E7E4;
$color-white: #ffffff;
$color-light-gray: #F3F6F7;
$color-medium-gray: #F0F0F0;
$color-dark-gray: #8A96A1;
$color-darker-gray: #677480;
$color-disabled: #ADC1C7;
$color-info-light: #D0E7FE;
$color-info: #9EE0ED;
$color-success: #A4EDB0;
$color-warning: #F4CF71;
$color-rating: #ED8B00;
$color-danger: #FACED7;
$red: #E1384C;
$red-lighter: #F1404E;
$color-blue-gray:#E9F0F3;
$color-red-disabled:#E99BA4;
$color-grey-disabled:#d6d1d2;
$color-info:#364390