@import "../../../_variables.scss";


.page-title {
    display: flex;
    justify-content: flex-start;
    font-size: 2.188rem;
    font-weight: 500;
    letter-spacing: 0.25px;
    padding: 0 2.5rem;
    margin-bottom: 0px;
    line-height: 2.563rem;

}

.workup-root {
    display: flex;
    margin-bottom: 2rem;
    padding: 2.5rem 2.5rem 0 2.5rem;
    box-sizing: border-box;

    .left-sec {
        .MuiTextField-root.search {
            .MuiOutlinedInput-root {
                border-radius: 3.125rem;
                width: 25rem;
            }

            .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $color-gray;
                }
            }

        }
    }

    .right-sec {
        display: flex;
        margin-left: auto;

        .filter-right-sec {
            margin-right: 0.625rem;

            .MuiButtonBase-root {
                background-color: $color-white;
                color: $color-primary;
                border: 1px solid $color-primary;
            }
        }

        .MuiButtonBase-root {
            background-color: $color-primary;
            color: $color-white;
            font-size: 0.875rem;
            line-height: 1.125rem;
            font-weight: 500;
            padding: 0.625rem 2rem;
            border-radius: 0.313rem;
            text-transform: none;
            cursor: pointer;
            
        }
    }
}

.workup-table-root {
    padding: 0 2.5rem;

    .MuiBox-root {

        .table-container {

            table.table {
                border: 1px solid $color-light-gray;
                border-radius: 1rem;

                tr {
                    th.MuiTableCell-root {
                        padding: 0.969rem 0.75rem;
                        font-weight: 600;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        color: $color-secondary;
                        background: $color-light-gray;
                        border-bottom: 1px solid $color-medium-gray;
                        max-width: 15rem;

                        .table-col-heading-wrapeer {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                           
                        }

                        .table-sorting-icons {
                            display: inline-grid;
                            float: right;
                            margin-left: 0.25rem;
                            cursor: pointer;

                            :first-child {
                                margin-bottom: 0.25rem;
                            }
                        }

                        .red {
                            path {
                                fill: $color-primary;
                            }
                        }
                    }

                    &.selected {
                        background-color: $color-blue-gray;
                    }

                    td.MuiTableCell-root {
                        padding: 0.969rem 0.75rem;
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        color: $color-secondary;
                        border-bottom: 1px solid $color-medium-gray;
                        max-width: 15rem;
                        .MuiSvgIcon-fontSizeMedium {
                            fill: $color-secondary;
                        }

                    }

                    a {
                        color: $color-primary;
                        text-decoration-color: $color-primary;
                        font-weight: 500;
                    }
                }

                .status {
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    letter-spacing: 0.02rem;
                    text-transform: uppercase;
                    padding: 0.25rem 0.5rem;
                    border-radius: 62.438rem;
                }

                .expand-row-container {
                    background-color: $color-light-gray;

                    .MuiTableCell-sizeSmall {
                        border-bottom: none;
                    }

                    .expand-row-label {
                        font-size: 1rem;
                        padding-top: 0rem;
                        padding-left: 0rem;

                    }

                    .expand-row-headers {
                        padding-left: 0rem;
                        color: $color-dark-gray;
                    }

                }
            }
        }
    }

    
}

.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
    padding: 20px 40px;
    
    .cancel {
      padding: 13px 32px;
      background: $color-white;
      border: 1px solid $color-white;
      color: $color-primary;
      cursor: pointer;
    }

    .submit {
        border: 1px solid $color-primary;
        padding: 13px 16.5px;
        gap: 8px;
        color: $color-white;
        margin-left: 24px;
        text-transform: none;
        &:hover {
          background: $color-primary;
          text-transform: none;
        }
    }
}



.patient.MuiMenu-root.MuiMenu-root .MuiPaper-root {
    justify-content: end;
    background: $color-white;
    box-shadow: 0px 0.125rem 0.438 rgb(0 0 0 / 10%);
    border-radius: 4px;
    width: 10rem;
    right: 5%;


}

.MuiMenu-root .MuiPaper-root ul.MuiMenu-list li.MuiMenuItem-root {

    background-color: $color-white;
    text-align: center;
    align-items: center;
    padding: 0.313rem;
    margin: 0 0.25rem 0.5rem 0.5rem;
    a {
        text-decoration: none;
        color: inherit
    }
}