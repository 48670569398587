@import "../../_variables.scss";

.footer-root {
  display: flex;
  background-color: $color-secondary;
  color: $color-medium-gray;
  justify-content: space-between;
  padding: 7%;
  font-size: 14px;

  .privacy-notice {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .legal {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .code-of-conduct {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .support {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .contact {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
