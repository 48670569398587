@import "../../_variables.scss";

.test-table-root {
    padding: 0 2.5rem;

    .MuiBox-root {

        .table-container {

            table.table {
                border: 0.0625rem solid $color-light-gray;
                border-radius: 1rem;

                tr {
                    th.MuiTableCell-root {
                        padding: 0.969rem 0.75rem;
                        font-weight: 600;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        color: $color-secondary;
                        background: $color-light-gray;
                        border-bottom: 0.0625rem solid $color-medium-gray;

                        .table-col-heading-wrapeer {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .table-sorting-icons {
                            display: inline-grid;
                            float: right;
                            margin-left: 0.25rem;
                            cursor: pointer;

                            :first-child {
                                margin-bottom: 0.25rem;
                            }
                        }

                        .red {
                            path {
                                fill: $color-primary;
                            }
                        }
                    }

                    &.selected {
                        background-color: $color-blue-gray;
                    }

                    td.MuiTableCell-root {
                        padding: 0.969rem 0.75rem;
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        color: $color-secondary;
                        border-bottom: 0.0625rem solid $color-medium-gray;
                    }

                    a {
                        color: $color-primary;
                        text-decoration-color: $color-primary;
                        font-weight: 500;
                    }
                }

                .status {
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                    padding: 0.25rem 0.5rem;
                    border-radius: 62.438rem;
                }
                #add-button {
                    border: 1px solid $color-primary;
                    color: $color-primary;
                    border-radius: 0.313rem;
                    padding: 0.25rem 0.5rem;
                    font-size: 0.75rem;
                    font-weight: 500;
                }
            }
        }
    }
}

.more-icon {
    color: $color-secondary;
}

.no-result-found {
    padding: 0rem 2.5rem;
    text-align: center;

    .circle {
        justify-content: center;
        margin: 0rem auto;
        background-color: $color-primary-light;
        border: 0.0625rem solid $color-gray;
        height: 14.563rem;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        width: 14.563rem;
        display: flex;
        align-items: center;

    }
}

.filter-root {
    .search {
        .MuiInputBase-root-MuiOutlinedInput-root {
            border-radius: 3.688rem;
        }
    }
}