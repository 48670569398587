@import "../../_variables.scss";

.MuiPaper-root {
  .MuiToolbar-root {
    background-color: $color-primary;
    
    .MuiButtonBase-root {
      .MuiBadge-root {
        .notification-icon {
          font-size: 1.6rem;
        }
      }

      .user-avatar {
        font-size: 2.6rem;
      }
    }

    .user-details {
      padding: 0 0 0 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      
      .user-name{
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color-white;
        margin-bottom: 4px;
      }
      .user-destination{
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $color-white;
      }
    }
    .login-button{
      cursor:pointer;
      margin:12px
    }
  }
}