@import "../../_variables.scss";

.page-title {
  display: flex;
  justify-content: flex-start;
  font-size: 35px;
  padding: 0 40px;
}
.create-workup-header-testtable {
  padding: 0 40px;
    margin-bottom: -50px;
  b {
    font-weight: 500;
    font-size: 16px;
    line-height: 0px;
    margin-top: 48px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    color: $color-secondary;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #748492;
    padding-bottom: 48px;
  }
}
.button {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
    display: flex;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 5px;
    border: 1px solid $color-primary;
    background-color: $color-white;
    color: $color-primary;
}
.optional {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: $color-primary;
  padding-left:5px;
}
.button-container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border-radius: 5px;
  padding-top:20px;
  .cancel {
    text-decoration: none;
    padding: 13px 32px;
    background: $color-white;
    border: 1px solid $color-white;
    color: $color-primary;
  }
  .submit {
    text-decoration: none;
    background: $color-primary;
    border-radius: 5px;
    border: 1px solid $color-primary;
    padding: 13px 16.5px;
    gap: 8px;
    color: $color-white;
    margin-left: 24px;
  }
}

.patient-root {
  display: flex;
  margin-bottom: 32px;
  padding: 40px 40px 0 40px;
  box-sizing: border-box;

  .left-sec {
    .MuiTextField-root.search {
      .MuiOutlinedInput-root {
        border-radius: 50px;
        width: 25rem;
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $color-gray;
        }
      }
    }
  }

  .right-sec {
    margin-left: auto;

    .MuiButtonBase-root {
      background-color: $color-primary;
      color: $color-white;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      padding: 10px 32px;
      border-radius: 5px;
      text-transform: none;
      cursor: pointer;
    }
  }
}

.patient-table-root {
  padding: 0 40px;

  .MuiBox-root {
    text-align: left;

    .patient-name {
      color: $color-primary;
      text-decoration: underline;
      text-align: left;

            a {
                color: $color-primary;
            }
        }

        .table-container {
            .table {
                .table-head {
                    background-color: $color-light-gray;
                    .tr.MuiTableRow-root {
                        .MuiTableRow-head, th.MuiTableCell-root{
                            background-color: $color-light-gray;
                        }
                        

                        .table-header-cell {
                            font-weight: 600;
                        }

                        .table-sorting-icons {
                            display: inline-grid;
                            float: right;
                            margin-left: 4px;
                            cursor: pointer;

                            :first-child {
                                margin-bottom: 4px;
                            }
                        }
                        .red {
                            path {
                                fill: $color-primary;
                            }
                        }
                    }

                }

                .table-body {

                    .MuiTableRow-hover:hover,
                    MuiTableRow-hover:active {
                        background-color: $color-blue-gray;
                    }
                }

            }
        }
    }

    .table-container {
      .table {
        .table-head {
          background-color: $color-light-gray;
          .tr.MuiTableRow-root {
            .MuiTableRow-head,
            th.MuiTableCell-root {
              background-color: $color-light-gray;
            }

            .table-header-cell {
              font-weight: 600;
            }

            .table-sorting-icons {
              display: inline-grid;
              float: right;
              margin-left: 4px;
              cursor: pointer;

              :first-child {
                margin-bottom: 4px;
              }
            }
          }
        }

        .table-body {
          .MuiTableRow-hover:hover,
          MuiTableRow-hover:active {
            background-color: $color-blue-gray;
          }
        }
      }
    }
  }
.more-icon {
  color: $color-secondary;
}
