.homepage-layout-root{
    .homepage-main-wrapper{
        display: flex;

        .homepage-sidebar{
            width: 6%
        }
        .homepage-right-block-wrapper{
            width: 94%;

    
        }
    }
}