@import "../../_variables.scss";

.dialog-box {
  .alert-dialog-title {
    font-size: 1rem;
    color: $color-secondary;
    margin-bottom: 1.5rem;
  }
.close-icon{
  float:right;
  margin-left: auto;
  
}
  .alert-dialog-description {
    font-size: 0.875rem;
    color: $color-secondary
  }

  .alert-cancel {
    margin-top: 1rem;
    border: 1px solid #34383C;
    color: $color-secondary;
    background-color: $color-white;
    padding: 7.5px 2rem;
    border-radius: 0.3125rem;
    text-transform: none;
  }

  .alert-submit {
    margin-top: 1rem;
    padding: 7.5px 2rem;
    border-radius: 0.3125rem;
    color: $color-white;
    background-color: $color-primary;
    text-transform: none;
  }

  .alert-cancel:hover {
    border: 1px solid #34383C;
    ;
  }

  .alert-submit:hover {
    background-color: $color-primary;
  }

}