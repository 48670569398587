@import "../../../variables.scss";
.classification-tab-autocontrol {
  .label-row {
    display: flex;
    p {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #34383c;
    }

    .label {
      display: flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $color-secondary-dark;
      margin-right: 50px;
      padding: 35px 0 28px;

      span {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 8px;
        display: inline-block;
      }
    }
    .ruled-in {
      span {
        background: $color-black-light;
      }
    }
    .ruled-out {
      span {
        background: $color-white;
      }
    }
    .undetermined {
      span {
        background: $color-disabled;
      }
    }
  }
  .values-row {
    display: flex;
    //   justify-content: flex-start;
    b {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #34383c;
      span {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-feature-settings: "cpsp" on;
        color: #34383c;
        padding-left:15px
      }
    }
    .classfic-wrapper {
      flex: 1;

      .classfic-cell {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $color-secondary;
        background: $color-disabled;
        margin: 0 2px 8px 0;
        border-radius: 8px;
        padding: 22.5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .classific-ruled-in-bg {
        color: $color-white;
        background: $color-black-light;
      }
      .classific-ruled-out-bg {
        color: $color-secondary;
        background: $color-white;
      }
      .classific-undetermined {
        color: $color-secondary;
        background: $color-disabled;
      }
      .count {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.96);
        background: $color-black-light;
        opacity: 0.94;
        border-radius: 8px;
        padding: 4px;
      }
    }
  }
}
