@import "../../../_variables.scss";

.antigram-panel-header-wrapper {
  // background: $color-white;
  // position: sticky;
  // position: -webkit-sticky;
  // top: 0px;
  // z-index: 999;
.workup-created-message{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 17px 16px 0px 19px;
   align-items: flex-start;
  gap: 10px;
  position: absolute;
  width: 392px;
  height: 80px;
  left: 624px;
  top: 84px;
  background: #eefff1;
  border: 1px solid #789d4a;
  box-shadow: 0px 8px 16px rgba(39, 40, 51, 0.2);
  border-radius: 4px;
}
  .antigram-panel-header {
    .antigram-title-row {
      display: flex;
      align-items: center;
      padding: 24px 40px;
      border-bottom: 1px solid $color-gray;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

      .workuptitle {
        font-weight: 500;
        font-size: 35px;
        line-height: 41px;
        letter-spacing: 0.25px;
        color: $color-secondary;
        margin: 0;
        display: inline-block;
      }
      .edit-vector-icon{
        margin-left: 16px;
        padding: 4px 8px;
        cursor: pointer;
      }

      .status {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $color-secondary;
        background: $color-warning;
        border-radius: 999px;
        margin-left: 16px;
        padding: 4px 8px;
      }
      .completed-status {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $color-secondary;
        background: $color-success;
        border-radius: 999px;
        margin-left: 16px;
        padding: 4px 8px;
      }

      .last-modified {
        color: $color-dark-gray;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-left: 16px;
      }

      button {
        padding: 13px 32px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        border-radius: 5px;
        text-transform: capitalize;
      }

      .wk-notes-btn {
        margin-left: auto;
        color: $color-primary;

        .notes-count {
          font-size: 10px;
          line-height: 15px;
          font-weight: 500;
          background: $color-primary;
          border-radius: 8px;
          color: $color-white;
          text-align: center;
          min-height: 16px;
          min-width: 16px;
          margin-left: 8px;
        }
      }

      .wk-save-btn {
        margin-left: 24px;
        color: $color-primary;
      }

      .wk-submit-btn {
        margin-left: 24px;
        background: $color-primary;
      }

    }

    .antigram-patient-details-row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-gray;
      padding: 24px 40px;

      .patient-data-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .label {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: $color-dark-gray;
          margin-bottom: 4px;
        }

        .value {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $color-secondary;
        }
      }
      button{
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $color-primary;
        text-transform: capitalize;
        padding: 13px 32px;
        border: 1px solid $color-primary;
        border-radius: 5px;
      }
    }

    .antigram-customization-row {
      background: $color-light-gray;

      .antigram-customization-toggle-wrapper {
        padding: 16px 40px 34px;
        margin-bottom: 16px;
        
        .cust-box-wrapper{
          .tab-row-wrapper {
            display: flex;
            align-items: center;
            background: $color-light-gray;
            border-bottom: 1px solid rgba(40, 51, 61, 0.25);

            .option-sec{
              display: flex;
              align-items: center;

              .view-label{
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
                color: $color-black;
              }

              .toggle-btn{
                margin-left: 8px;

                .MuiToggleButtonGroup-root{
                  background: $color-white;
                  border: 1px solid $color-primary;
                  border-radius: 50px;

                  button{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: $color-primary;
                    opacity: 0.6;
                    border: none;
                    border-radius: 50px;
                  }
                  button.Mui-selected{
                    color: $color-white;
                    background: $color-primary;
                    opacity: 0.94;
                    z-index: 999;
                    outline: 1px solid $color-primary;

                    svg{
                      margin-left: 0.625rem;
                    }
                  }
                  .btn-rec{
                    padding: 6px 16px;
                  }
                  .btn-manual{
                    padding: 6px 21px;
                    margin-left: -5px;
                  }
                }
              }

              .rules-btn{
                margin-left: 24px;
                button{
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 22px;
                  text-align: center;
                  letter-spacing: 0.15px;
                  text-transform: uppercase;
                  color: $color-primary;
                  border: 1px solid $color-primary;
                  border-radius: 50px;
                  padding: 7px 40px;

                  .rule-badge{
                    background: $color-primary;
                    opacity: 0.94;
                    border-radius: 4px;
                    padding: 2px 4px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.96);
                    margin: 0 6px 0 8px;
                    word-break: keep-all;
                    white-space: nowrap;
                  }
                }
              }
            }
  
            .tabs-group {
              margin-left: auto;
  
              .MuiTabs-flexContainer{
                button {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 18px;
                  text-align: center;
                  text-transform: capitalize;
                  color: $color-secondary;
                  padding: 23px 38px;
                  opacity: 0.5;

                  svg{
                    background: #425E66;
                    color: #ffffff;
                    border-radius: 50%;
                    margin: 0 0 0 0.563rem;
                    width: 1.25rem;
                    height: 1.25rem;
                  }
                }
                button.Mui-selected{
                  opacity: 1;
                }
              }
              .MuiTabs-indicator{
                background-color: $color-secondary;
              }
            }

            .MuiAccordionSummary-root{
              padding: 0px;
            }
          }
          .MuiAccordion-root{
            background: $color-light-gray;
            box-shadow: none;

            .MuiAccordionDetails-root{
              padding: 0;

              .MuiBox-root{
                padding: 0;

                .classification-tab{
                  .label-row{
                    display: flex;

                    .label{
                      display: flex;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 17px;
                      color: $color-secondary-dark;
                      margin-right: 50px;
                      padding: 35px 0 28px;

                      span{
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        margin-right: 8px;
                        display: inline-block;
                      }
                    }
                    .ruled-in{
                      span{
                        background: $color-black-light;
                      }
                    }
                    .ruled-out{
                      span{
                        background: $color-white;
                      }
                    }
                    .undetermined{
                      span{
                        background: $color-disabled;
                      }
                    }
                  }
                  .values-row{
                    display: flex;
                    justify-content: center;
    
                    .classfic-wrapper{
                      flex: 1;

                      .classfic-cell{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        color: $color-secondary;
                        background: $color-disabled;
                        margin: 0 2px 8px 0;
                        border-radius: 8px;
                        padding: 22.5px 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                      .classific-ruled-in-bg{
                        color: $color-white;
                        background: $color-black-light;
                      }
                      .classific-ruled-out-bg{
                        color: $color-secondary;
                        background: $color-white;
                      }
                      .classific-undetermined{
                        color: $color-secondary;
                        background: $color-disabled;
                      }
                      .count{
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 14px;
                        color: rgba(255, 255, 255, 0.96);
                        background: $color-black-light;
                        opacity: 0.94;
                        border-radius: 8px;
                        padding: 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }
}

.rule-dropdown{
  .MuiPaper-root ul.MuiList-root{
    padding: 24px;
    
    .dropdn-list{
      display: block;
      padding: 8px 19px 8px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $color-darker-gray;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
    }
    .dropdn-list.active{
      color: $color-secondary;
      font-weight: 600;

      span{
        margin-left: auto;
      }
    }
    
    button{
      padding: 13px 32px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      display: block;
      width: 100%;
      cursor: pointer;
    }

    button.btn-outline{
      color: $color-primary;
      border: 1px solid $color-primary;
      background: none;
      margin-bottom: 13px;
    }

    button.btn-solid{
      color: $color-white;
      background: $color-primary;
      border: none;
    }
  }
}