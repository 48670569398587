@import "../../_variables.scss";

.box-wrapper{
    display: flex;
    color: $color-secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 20px 40px 24px 40px;
    box-sizing: border-box;
    
    span{
        color:$color-dark-gray;
        
    }
    .MuiPagination-text {
        .MuiPagination-ul {
            .MuiPaginationItem-circular.Mui-selected {
                background: none;
                color: $color-primary;
            }
        }
    }
}