@import "../../_variables.scss";
.MuiInputBase-root-MuiFilledInput-root {
  border-bottom: 0px;
}
.css-10g5aos-MuiTypography-root-MuiDialogContentText-root {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #34383c;
}
.create-workup-container {
  margin-top: 24px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 57px;

  .optional {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: $color-primary;
    padding-left: 5px;
  }
  .button {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
    display: flex;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 5px;
    border: 1px solid $color-primary;
    background-color: $color-white;
    color: $color-primary;
  }
  b {
    font-weight: 500;
    font-size: 16px;
    line-height: 0px;
    margin-top: 48px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    color: $color-secondary;
  }
  .create-workup-header {
    span {
      color: $color-black;
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      display: flex;
      align-items: center;
      letter-spacing: 0.25px;
      padding-bottom: 8px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #748492;
      padding-bottom: 48px;
    }
  }
  .workup-information {
    b {
      padding-bottom: 24px;
    }
    .MuiBox-root.workup-input {
      .MuiFormControl-root {
        display: block;
        .MuiFilledInput-root {
          display: block;
          padding: 13px 12px;
          gap: 10px;
          width: 50%;
          height: 48px;
          background: $color-light-gray;
          border-radius: 5px;
          padding-bottom: 48px;
          .MuiFilledInput-input::after {
            border: none;
          }
        }
      }
    }
    .workup-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: $color-secondary;
      padding-bottom: 6px;
      #mandatory {
        color: $color-primary;
      }
    }
  }
  .patient-information-container {
    .patient-info {
      display: flex;
      text-align: left;
      padding: 24px;
      background: $color-light-gray;
      border-radius: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $color-dark-gray;
    }
    #data {
      font-size: 16px;
      line-height: 21px;
      color: $color-secondary;
      margin-top: 4px;
    }
    .patient-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      flex: auto;
    }
  }
  .test-info-container {
    .test-info {
      display: flex;
      text-align: left;
      padding: 24px;
      background: $color-light-gray;
      border-radius: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $color-dark-gray;
      margin-bottom: 10px;
    }
    .test-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      flex: auto;
    }
    .status {
      font-weight: 500;
      font-size: 12px;
      line-height: 1rem;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      padding: 0.25rem 0.5rem;
      border-radius: 62.438rem;
    }
    #data {
      font-size: 16px;
      line-height: 21px;
      color: $color-secondary;
      margin-top: 4px;
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
    padding-top: 20px;
    .cancel {
      padding: 13px 32px;
      background: $color-white;
      border: 1px solid $color-white;
      color: $color-primary;
    }
    .submit {
      // background: $color-primary;
      border: 1px solid $color-primary;
      padding: 13px 16.5px;
      gap: 8px;
      color: $color-white;
      margin-left: 24px;
      text-transform: none;
      &:hover {
        background: $color-primary;
        text-transform: none;
      }
    }
  }
}
// .MuiDialog-root .remove-test-modal {
//   .MuiBackdrop-root{
//   .MuiDialog-container {
//     .MuiPaper-root{
//     .MuiTypography-root {
//       .MuiDialogContent-root{
//       .remove-modal-content {
//         color: "34383C";
//         line-height: "21px";
//       }
//     }
//     }
//     .MuiButtonBase-root.alert-cancel {
//       color: "#34383C";
//       border: "1px solid #34383C";
//       padding: "8px 22px";
//       font-size: "12px";
//     }
//     .MuiButtonBase-root.alert-submit {
//       padding: "9px 22px";
//       font-size: "12px";
//     }
//   }
//   }
// }
// }
